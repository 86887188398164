import request from '@/utils/request'
import { listSeat } from '@/api/Seat'
const state = {
  seatView: [],
  seatInfo: []
}
const mutations = {
  // 在原数据基础上增改，为座位图渲染和列表做准备
  setSeatInfo(state, seatList) {
    const seatInfo = []
    const empty = {
      seatNumber: '',
      visable: false
    }
    const reg = /^(1|9|([1][7-9])|2[0-3])$/
    seatList.forEach((obj, index) => {
      obj.seatNumber = index + 1
      obj.seatAvailable = obj.ownerUid ? false : true
      obj.seatReserve = obj.seatAvailable ? '可预定' : '已被预定'
      obj.visable = true
      obj.isReserve = false //是否被当前登录用户预约
    })
    state.seatInfo = seatList
    let j = 0
    for (let i = 0; i < 32; i++) {
      if (reg.test(i)) {
        seatInfo[i] = empty
      } else {
        seatInfo[i] = seatList[j]
        j++
      }
    }
    state.seatView = seatInfo
  }
}
const actions = {
  // 获取座位列表信息
  async fetchSeatlist({ commit }) {
    let seatList = []
    try {
      const res = await listSeat()
      seatList = res.data.message
      commit('setSeatInfo', seatList)
    } catch (err) {
      throw new Error(err)
    }
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
